import _objectAssign2 from "object-assign";
var exports = {};
exports.__esModule = true;
exports.compose = exports.system = exports.createStyleFunction = exports.createParser = exports.get = exports.merge = void 0;

var _objectAssign = _interopRequireDefault(_objectAssign2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var merge = function merge(a, b) {
  var result = (0, _objectAssign["default"])({}, a, b);

  for (var key in a) {
    var _assign;

    if (!a[key] || typeof b[key] !== "object") continue;
    (0, _objectAssign["default"])(result, (_assign = {}, _assign[key] = (0, _objectAssign["default"])(a[key], b[key]), _assign));
  }

  return result;
}; // sort object-value responsive styles


exports.merge = merge;

var sort = function sort(obj) {
  var next = {};
  Object.keys(obj).sort(function (a, b) {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base"
    });
  }).forEach(function (key) {
    next[key] = obj[key];
  });
  return next;
};

var defaults = {
  breakpoints: [40, 52, 64].map(function (n) {
    return n + "em";
  })
};

var createMediaQuery = function createMediaQuery(n) {
  return "@media screen and (min-width: " + n + ")";
};

var getValue = function getValue(n, scale) {
  return get(scale, n, n);
};

var get = function get(obj, key, def, p, undef) {
  key = key && key.split ? key.split(".") : [key];

  for (p = 0; p < key.length; p++) {
    obj = obj ? obj[key[p]] : undef;
  }

  return obj === undef ? def : obj;
};

exports.get = get;

var createParser = function createParser(config) {
  var cache = {};

  var parse = function parse(props) {
    var styles = {};
    var shouldSort = false;
    var isCacheDisabled = props.theme && props.theme.disableStyledSystemCache;

    for (var key in props) {
      if (!config[key]) continue;
      var sx = config[key];
      var raw = props[key];
      var scale = get(props.theme, sx.scale, sx.defaults);

      if (typeof raw === "object") {
        cache.breakpoints = !isCacheDisabled && cache.breakpoints || get(props.theme, "breakpoints", defaults.breakpoints);

        if (Array.isArray(raw)) {
          cache.media = !isCacheDisabled && cache.media || [null].concat(cache.breakpoints.map(createMediaQuery));
          styles = merge(styles, parseResponsiveStyle(cache.media, sx, scale, raw, props));
          continue;
        }

        if (raw !== null) {
          styles = merge(styles, parseResponsiveObject(cache.breakpoints, sx, scale, raw, props));
          shouldSort = true;
        }

        continue;
      }

      (0, _objectAssign["default"])(styles, sx(raw, scale, props));
    } // sort object-based responsive styles


    if (shouldSort) {
      styles = sort(styles);
    }

    return styles;
  };

  parse.config = config;
  parse.propNames = Object.keys(config);
  parse.cache = cache;
  var keys = Object.keys(config).filter(function (k) {
    return k !== "config";
  });

  if (keys.length > 1) {
    keys.forEach(function (key) {
      var _createParser;

      parse[key] = createParser((_createParser = {}, _createParser[key] = config[key], _createParser));
    });
  }

  return parse;
};

exports.createParser = createParser;

var parseResponsiveStyle = function parseResponsiveStyle(mediaQueries, sx, scale, raw, _props) {
  var styles = {};
  raw.slice(0, mediaQueries.length).forEach(function (value, i) {
    var media = mediaQueries[i];
    var style = sx(value, scale, _props);

    if (!media) {
      (0, _objectAssign["default"])(styles, style);
    } else {
      var _assign2;

      (0, _objectAssign["default"])(styles, (_assign2 = {}, _assign2[media] = (0, _objectAssign["default"])({}, styles[media], style), _assign2));
    }
  });
  return styles;
};

var parseResponsiveObject = function parseResponsiveObject(breakpoints, sx, scale, raw, _props) {
  var styles = {};

  for (var key in raw) {
    var breakpoint = breakpoints[key];
    var value = raw[key];
    var style = sx(value, scale, _props);

    if (!breakpoint) {
      (0, _objectAssign["default"])(styles, style);
    } else {
      var _assign3;

      var media = createMediaQuery(breakpoint);
      (0, _objectAssign["default"])(styles, (_assign3 = {}, _assign3[media] = (0, _objectAssign["default"])({}, styles[media], style), _assign3));
    }
  }

  return styles;
};

var createStyleFunction = function createStyleFunction(_ref) {
  var properties = _ref.properties,
      property = _ref.property,
      scale = _ref.scale,
      _ref$transform = _ref.transform,
      transform = _ref$transform === void 0 ? getValue : _ref$transform,
      defaultScale = _ref.defaultScale;
  properties = properties || [property];

  var sx = function sx(value, scale, _props) {
    var result = {};
    var n = transform(value, scale, _props);
    if (n === null) return;
    properties.forEach(function (prop) {
      result[prop] = n;
    });
    return result;
  };

  sx.scale = scale;
  sx.defaults = defaultScale;
  return sx;
}; // new v5 API


exports.createStyleFunction = createStyleFunction;

var system = function system(args) {
  if (args === void 0) {
    args = {};
  }

  var config = {};
  Object.keys(args).forEach(function (key) {
    var conf = args[key];

    if (conf === true) {
      // shortcut definition
      config[key] = createStyleFunction({
        property: key,
        scale: key
      });
      return;
    }

    if (typeof conf === "function") {
      config[key] = conf;
      return;
    }

    config[key] = createStyleFunction(conf);
  });
  var parser = createParser(config);
  return parser;
};

exports.system = system;

var compose = function compose() {
  var config = {};

  for (var _len = arguments.length, parsers = new Array(_len), _key = 0; _key < _len; _key++) {
    parsers[_key] = arguments[_key];
  }

  parsers.forEach(function (parser) {
    if (!parser || !parser.config) return;
    (0, _objectAssign["default"])(config, parser.config);
  });
  var parser = createParser(config);
  return parser;
};

exports.compose = compose;
export default exports;
export const __esModule = exports.__esModule;
const _compose = exports.compose,
      _system = exports.system,
      _createStyleFunction = exports.createStyleFunction,
      _createParser2 = exports.createParser,
      _get = exports.get,
      _merge = exports.merge;
export { _compose as compose, _system as system, _createStyleFunction as createStyleFunction, _createParser2 as createParser, _get as get, _merge as merge };